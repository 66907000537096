<template>
<div class="error-holder d-flex justify-content-center align-self-center">
    <div class="error text-center">
        We Couldn't find what you are looking for 😢
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style src="./NotFound.css" scoped>

</style>
